<template>
    <div >

        <div class="box-header">
            <div class="row">
                <div class="col-md-4">
                    <h4 class="title-in-h">{{$t('contract.index.title')}}</h4>
                    <label v-show="info_page.status==='active'">{{$t('contract.index.has')+' '+ info_page.actives+' '+$t('contract.index.a_has_plural') }}</label>
                    <label v-show="info_page.status==='caducate'">{{$t('contract.index.has')+' '+ info_page.actives+' '+$t('contract.index.c_has_plural') }}</label>
                    <label v-show="info_page.status==='inactive'">{{$t('contract.index.has')+' '+ info_page.actives+' '+$t('contract.index.i_has_plural') }}</label>
                </div>
                <!--<div class="col-md-5 pt-2">
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status===true?'btn-success':'btn-outline'" @click="change_status(true)">{{$t('form.actives')}}</button>
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status===false?'btn-success':'btn-outline'" @click="change_status(false)">{{$t('form.inactives')}}</button>
                </div>-->
                <div class="col-md-8 pt-2">
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='active'?'btn-success':'btn-outline'" @click="change_status('active')">{{$t('contract.filter.vigencies')}}</button>
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='caducate'?'btn-success':'btn-outline'" @click="change_status('caducate')">{{$t('contract.filter.caducated')}}</button>
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='inactive'?'btn-success':'btn-outline'" @click="change_status('inactive')">{{$t('contract.filter.inactives')}}</button>
                </div>
            </div>
        </div>



        <div class="row">

            <div class="col-12">
                <card v-loading="screenLoading"
                      element-loading-lock="true"
                      element-loading-customClass="min-height-large"
                      :element-loading-text="screenText"
                      element-loading-spinner="el-icon-loading">
                    <div>
                        <vue-topprogress ref="topProgress"></vue-topprogress>
                        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                            <div class="mb-3" style="width: 200px">

                                <l-button class="btn-default" @click="createItem()"  v-if="writting_permission" >{{$t('contract.index.new')}}</l-button>
                            </div>
                            <el-input type="search"
                                      class="mb-3"
                                      style="width: 200px"
                                      :placeholder="$t('contract.index.search') "
                                      v-model="searchQuery"
                                      aria-controls="datatables"/>
                        </div>
                        <div class="col-sm-12">

                            <el-table class="table-bigboy"
                                      style="width: 100%"
                                      :data="displayData">



                                <el-table-column min-width="150"  :label="$t('contract.index.title') ">
                                    <template slot-scope="{row}">
                                        <div class="lbl-container  text-center">
                                            <i class="fa fa-star mr-1" v-if="!row.rateplan_link"></i>
                                            <label class="d-inline-block">{{row.name}}</label>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column min-width="100"  :label="$t('contract.table.cod') ">
                                    <template slot-scope="{row}">
                                        <div class="lbl-container text-center">
                                            <label>{{row.cod}}</label>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column min-width="150"  :label="$t('contract.table.plan') ">
                                    <template slot-scope="{row}">
                                        <div class="lbl-container  text-center">
                                            <label>{{getName(info_page.types, row.meal_plan)}}</label>

                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column min-width="150"  :label="$t('contract.form.vigency') ">
                                    <template slot-scope="{row}">
                                        <div class="lbl-container  text-center">
                                            <label v-html="getVigency(row)"></label>

                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column min-width="150"  :label="$t('contract.table.vinculo') ">
                                    <template slot-scope="{row}">
                                        <div class="lbl-container text-center">
                                            <label>{{ (row.rateplan_link && items_all.length>0)?getName(items_all, row.rateplan_link.rateplan)+' '+row.rateplan_link.type+' '+row.rateplan_link.value:''}} </label>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column min-width="200"  :label="$t('contract.table.payment') ">
                                    <template slot-scope="{row}">
                                        <div class="lbl-container text-center">
                                            <label v-for="option in row.payment_options">{{getName(info_page.payments, option)}}</label>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column  width="180"  :label="$t('form.actions')"  v-if="writting_permission" >
                                    <template slot-scope="{row}">
                                        <div class="text-right box-custom">

                                            <i class="fa fa-link icon-left" aria-hidden="true" v-if="!row.is_public && info_page.booking_url!=='' " @click="alertCapture(info_page.booking_url, row.cod)"></i>
                                            <l-switch v-model="row.status" type="primary" on-text="Activo" off-text="Inactivo" @input="updateStatus(row._id, row.status, row.meal_plan)" v-if="row.linked_rateplans && row.linked_rateplans.length===0"></l-switch>


                                            <span class="fa fa-lock icon-right cursor-undrop"  v-if="row.linked_rateplans && row.linked_rateplans.length" v-tooltip.bottom="getInfo(row.linked_rateplans)"  ></span>

                                            <a v-tooltip.top-center="$t('form.edit')" class="btn-warning btn-simple btn-link ml-1"
                                               @click="goEdit(row._id)"><i
                                                    class="el-icon-edit"></i></a>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="">
                            <p class="card-category" v-if="total>0">
                                {{$t('form.showing_a')}} {{from + 1}} {{$t('form.showing_b')}} {{to}}
                                {{$t('form.showing_c')}} {{total}} {{$t('form.showing_d')}} </p>
                        </div>
                        <l-pagination class="pagination-no-border"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total="pagination.total">
                        </l-pagination>
                    </div>
                </card>
            </div>
        </div>

        <div class="box-footer" :class="(records.length)?'showme':''">
            <div class="box-inside text-center">
                <button class="btn btn-danger btn-wd btn-default ml-1 mr-1" @click="cancelForm">Cancelar</button>
                <button class="btn btn-success btn-wd btn-default ml-1 mr-1"  @click="saveChanges">Guardar</button>
            </div>
        </div>


    </div>

</template>
<script>
    import {Table, TableColumn, Select, Option} from 'element-ui'
    import {Pagination as LPagination, Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
    //import users from 'src/pages/Dashboard/Tables/users'
    import swal from 'sweetalert2'
    import Fuse from 'fuse.js'
    import ContractService from '../../../js/services/ContractService';
    import PropertyService from '../../../js/services/PropertyService';
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress';
    import moment from 'moment';
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';

    const contractService = new ContractService();
    const propertyService = new PropertyService();
    const logFunctions = new LogFunctions();
    const logService = new LogService();
    import { mapGetters } from "vuex";

    export default {
        components: {
            LPagination,
            Breadcrumb,
            BreadcrumbItem,
            LSwitch,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            vueTopprogress
        },
        computed: {
            ...mapGetters(["currentUser", "selected_property","writting_permission", "checkedView"]),
            pagedData() {
                return this.tableData.slice(this.from, this.to)
            },
            /***
             * Searches through table data and returns a paginated array.
             * Note that this should not be used for table with a lot of data as it might be slow!
             * Do the search and the pagination on the server and display the data retrieved from server instead.
             * @returns {computed.pagedData}
             */
            queriedData() {
                let result = this.tableData
                //console.log(this.searchQuery, result)
                if (this.searchQuery !== '') {


                    var fuse = new Fuse(result, this.options);
                    result = fuse.search(this.searchQuery);
                    //result = this.fuseSearch.search(this.searchQuery)
                    this.pagination.total = result.length
                }
                return result.slice(this.from, this.to)
            },
            to() {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from() {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total() {
                this.pagination.total = this.tableData.length
                return this.tableData.length
            },

            displayData() {

                let result = JSON.parse(JSON.stringify(this.tableData))

                //onsole.log('cP', this.pagination.currentPage)
                /*if(this.pagination.currentPage>1){

                    this.pagination.activePage = this.pagination.currentPage;
                }*/
                if (this.searchQuery !== '') {


                    var fuse = new Fuse(result, this.options);
                    result = fuse.search(this.searchQuery);
                    //result = this.fuseSearch.search(this.searchQuery)
                    this.pagination.total = result.length
                }
                if (!this.tableData || this.tableData.length === 0) return [];

                if(this.pagination.activePage){
                    this.pagination.currentPage = this.pagination.activePage;
                    this.pagination.activePage = 0;
                }


                return result.slice(this.from, this.to)

            },
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                record_model:{

                },
                records:[],

                info_page:{
                    original_info:{},
                    section_name: 'rateplan',
                    status: 'active',

                    booking_url: '',
                    actives: 4,
                    inactives: 0,
                    meal_plans:[],
                    types: [
                        {
                            _id: 1,
                            name: 'Plan Europeo'
                        },
                        {
                            _id: 2,
                            name: 'Plan Europeo + Desayuno'
                        },
                        {
                            _id: 3,
                            name: 'Todo Incluido'
                        }
                    ],
                    payments: [

                    ],
                },
                items: [],
                items_all: [],
                items_i: [],
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    activePage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: '',
                propsToSearch: ['name'],
                tableData: [],
                fuseSearch: null,
                options : {
                    shouldSort: true,
                    matchAllTokens: true,
                    threshold: 0.5,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name"
                    ]
                },
                section: 'ROOMS_RATES'
            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
            this.$store.dispatch('CHANGE_SECTION',this.section);

        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {                
                    if(this.$route.name==='Contracts'){
                        this.chargerItems(property_id);
                    }
                })
            },
            checkedAction(){
                EventBus.$on('CHECKED_VIEW', (checked) => {
                    console.log('CHECKED_VIEW contract', this.records.length);
                    if(this.records.length){
                        this.$store.dispatch('CHANGES_ACTIVES', true);
                    }else{
                        this.$store.dispatch('CHANGES_ACTIVES', false);
                    }
                })
            },
           

            async chargerItems(property_id,  changeView= true){
                this.initLoading();


                this.change_status('active');

                contractService.getContracts('all', property_id).then(async response => {

                    let rateplans = response.data.data.rateplans;

                    /*let rateplansActives = rateplans.filter(item => item.status === true)
                    let rateplansInactives = rateplans.filter(item => item.status === false)

                    this.items = rateplansActives;
                    this.info_page.actives = rateplansActives.length;

                    this.items_i = rateplansInactives;
                    this.info_page.inactives = rateplansInactives.length;
                    if(changeView){
                        this.tableData = rateplansActives;
                    }else{
                        this.tableData = rateplansInactives;
                    }*/
                    this.items_all = rateplans;


                });
                //console.log('=> chargerItems', this.pagination.currentPage);

                /*contractService.getContracts('true', property_id).then(async response => {
                    //console.log('rateplans', response.data.data.rateplans);
                    let rateplans = response.data.data.rateplans;
                    //console.log('items a',  rateplans);
                    this.items = rateplans;
                    this.info_page.actives = rateplans.length;
                    this.tableData = rateplans;

                    this.items_all = this.items.concat(this.items_i);
                    //this.$refs.topProgress.done();
                });
                contractService.getContracts('false', property_id).then(async response => {
                    this.items_i = response.data.data.rateplans;
                    this.info_page.inactives = response.data.data.rateplans.length;
                    //console.log('items i',  this.items_i);
                    this.items_all = this.items.concat(this.items_i);
                });*/
                let property = await propertyService.getPropertyId(property_id);
                property = property.data.data.property;
                this.info_page.booking_url = property.booking_url;
                if(property.plan_ep){
                    this.info_page.meal_plans.push(1)
                }
                if(property.plan_epb){
                    this.info_page.meal_plans.push(2)
                }
                if(property.plan_ai){
                    this.info_page.meal_plans.push(3)
                }



                if(property.pay_tdc){
                    this.info_page.payments.push( {
                        _id: 'TDD',
                        name: 'OPENPAY - TARJETA TDC/TDD'
                    })
                }

                if(property.pay_paypal){
                    this.info_page.payments.push({
                        _id: 'PAYPAL',
                        name: 'PayPal'
                    })
                }
                if(property.pay_hotel){
                    this.info_page.payments.push({
                        _id: 'ARRIVE',
                        name: 'Pago en destino'
                    })
                }
                if(property.pay_validate){
                    this.info_page.payments.push({
                        _id: 'VALIDATECARD',
                        name: 'Verificación de tarjeta'
                    })
                }
                if(property.pay_firstdata){
                    this.info_page.payments.push({
                        _id: 'FIRSTDATA',
                        name: 'FIRSTDATA - TARJETA TDC/TDD'
                    })
                }

                if(property.pay_stripe){
                    this.info_page.payments.push({
                        _id: 'STRIPE',
                        name: 'STRIPE - TARJETA TDC/TDD'
                    })
                }


                this.configLogs(property_id, this.info_page.section_name);
                this.closeLoading();
            },

            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },

            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 2;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            getInfo(rateplanData) {
                let stringR = '<div class="ul-rateplans"><span>Contratos vinculados:</span>'
                rateplanData.forEach((item, idx)=>{
                    stringR = stringR+ '<span>- '+item.name+'</span>'
                })
                stringR = stringR + '</div>'
                return stringR;

            },

            cancelForm(){
                this.chargerItems(this.selected_property._id);
                this.info_page.status = true;
                this.records = [];
                this.$store.dispatch('CHANGES_ACTIVES', false);
                /*let tableData = this.tableData;
                this.records.forEach(item => {
                    let element = tableData.find(xquery => xquery._id === item._id)
                    element.status = !element.status;
                })
                this.records = [];*/
            },

            updateStatus(id, status, meal_plan){
                let records = this.records;
                //console.log('event', id, meal_plan, this.info_page.meal_plans);

                let meal_status = this.info_page.meal_plans.findIndex(plan => plan === meal_plan)

               //console.log('event', cancellation_policy, meal_status);
                let error_c = 0;

                let all_items = this.items_all.find(record => record._id === id)
                let cancellation_policy = null;
                if(all_items.cancellation_policy){
                    cancellation_policy = all_items.cancellation_policy
                }



                if(meal_status<0 && (cancellation_policy=== null || cancellation_policy==='')){
                    error_c++;
                    let msg = "Es necesario agregar una política de cancelación y un tipo de plan"
                    this.alertError(id, msg)
                    all_items.status = !status;
                    return true;
                }


                if(cancellation_policy=== null || cancellation_policy===''){
                    let msg = "Es necesario agregar una política de cancelación"
                    this.alertError(id, msg)
                    all_items.status = !status;
                    error_c++;
                    return true;
                }

                if(meal_status<0 && (cancellation_policy!== null && cancellation_policy!=='')){
                    let infoMealPlan = this.info_page.types.find(item=> item._id === meal_plan)
                    let msg = "Es necesario activar el tipo de plan "+ infoMealPlan.name +" para continuar"
                    this.alertPlan(id, msg)
                    all_items.status = !status;
                    error_c++;
                }


                if(error_c===0){
                    status = !status;

                    this.$store.dispatch('CHANGES_ACTIVES', true);
                    records.findIndex(record => record._id === id)
                    let idx = records.findIndex(record => record._id === id);

                   //console.log(status, 'status')
                    if(idx>=0){
                        records.splice(idx, 1);
                    }else{
                        records.push({
                            _id: id,
                            to:all_items.to,
                            from:all_items.from,
                            status: status
                        })
                    }
                }
            },
            goEdit(id, changeActive = false){
                this.$router.push({
                    name: 'editContract',
                    params: {
                        id: id,
                        changeActive: changeActive,
                    }
                })
            },
            goConfig(){
                this.$router.push({
                    name: 'configurationProperty'
                })
            },


            async saveChanges(){
               //console.log('items modificados', this.records)
                let records = this.records;
                //this.initLoading('Actualizando contratos');
                //let currentpage = this.pagination.currentPage;

                let idx  = 0;

                while (idx < records.length){
                    let item = records[idx];
                    console.log('update status', item)
                    let status =item.status;
                    let id =item._id;


                    let all_items = this.items_all.find(record => record._id === id)
                    //console.log('update all_items', this.pagination.currentPage, this.info_page.status)
                    let cancellation_policy = all_items.cancellation_policy





                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                    if (indexToDelete >= 0) {
                        this.tableData.splice(indexToDelete, 1)
                    }

                    if(status===true){
                        /*indexToDelete = this.items_i.findIndex((tableRow) => tableRow._id === id);

                        console.log('update status true', indexToDelete)
                        if (indexToDelete >= 0) {
                            this.items_i.splice(indexToDelete, 1)
                        }*/
                        status = 'false';



                    }else{
                        /*indexToDelete = this.items.findIndex((tableRow) => tableRow._id === id);
                        console.log('update status else', indexToDelete)

                        if (indexToDelete >= 0) {
                            this.items.splice(indexToDelete, 1)
                        }*/
                        status = 'true';
                    }
                    //console.log('update status', id, status, cancellation_policy)




                    if(cancellation_policy!== null && cancellation_policy!==''){
                        let dataSend = {
                            status,
                            cancellation_policy

                        }

                        if(item.from===undefined || item.from===null){
                            dataSend.from = moment().subtract('2', 'days')
                            dataSend.to = moment().subtract('1', 'days')
                        }
                        let contract_resp = await contractService.updateStatus(id, dataSend)
                        //console.log('contract response', contract.data.data.rateplan)
                        let contract = contract_resp.data.data.rateplan;

                        if(contract.status===false){
                            await this.createLog(contract._id, 2, []);
                        }else{
                            await this.createLog(contract._id,  1, [{
                                parameter: 'status',
                                old: false,
                                new: true
                            }]);
                        }
                        this.closeLoading();

                        /*if(contract.status===true){
                            this.items.push(contract)
                        }else{
                            this.items_i.push(contract)
                        }
                        this.info_page.actives =  this.items.length;
                        this.info_page.inactives =  this.items_i.length;*/
                    }else{
                        this.alertError(id)
                    }


                    //await this.records.splice(idx, 1);
                    /*.then(response => {

                        let contract = response.data.data.rateplan;
                        //console.log(response.data, 'respuesta')

                    });*/
                    idx++;
                }

                /*records.forEach(async (item, idx)=>{


                });*/
                this.records = [];
                /*/this.chargerItems(this.selected_property._id, this.info_page.status)

                console.log('saveChanges', this.pagination.currentPage);
                this.pagination.activePage = this.pagination.currentPage;*/

            },

            alertError(id, msg){
                swal({
                    title: msg,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    confirmButtonText: 'Agregar',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    cancelButtonText: 'Cancelar',
                    buttonsStyling: false
                }).then((result)=> {
                    if (result.dismiss !== 'cancel') {
                        this.goEdit(id, true)


                    }
                });
            },
            alertPlan(id, msg){
                swal({
                    title: msg,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    confirmButtonText: 'Ir a configuración',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    cancelButtonText: 'Cancelar',
                    buttonsStyling: false
                }).then((result)=> {
                    if (result.dismiss !== 'cancel') {
                        this.goConfig()
                    }
                });
            },
            /*change_status(status){
                this.info_page.status = status;
                //console.log(this.info_page.actives, this.info_page.inactives)

                this.pagination.activePage = 1;
                if(status){
                    this.tableData =  this.items;
                }else{

                    this.tableData =  this.items_i;
                }
            },*/

            async change_status(type){
                this.initLoading();
                let items = [];
                this.info_page.status = type;

                let property_id = this.selected_property._id;

                //console.log(this.info_page.actives, this.info_page.inactives)
                let params = {};


                if(type === 'active'){
                    params = {
                        property_id: property_id,
                        status: true,
                        expired: false
                    }
                }else if(type === 'caducate'){
                    params = {
                        property_id: property_id,
                        expired: true,
                        status: true,

                    }
                }else if(type === 'inactive'){
                    params = {
                        property_id: property_id,
                        status: false,
                    }
                }

                let rateplans =  await contractService.getRateplanIndex(params);
                rateplans = rateplans.data.data.rateplans;
                //console.log('this.items',  promotions)
                //this.info_page.actives = rateplans.length;
                this.pagination.activePage = 1;

                this.info_page.actives = rateplans.length;
                this.tableData = rateplans;

                this.closeLoading();
            },

            handleEdit(id) {
                this.$router.push({
                    name: 'editContract',
                    params: {
                        id: id
                    }
                })
            },
            handleDelete(row) {
                swal({
                    title: this.$t('form.question_delete'),
                    text: this.$t('form.warning_delete'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: this.$t('form.yes_deleted'),
                    cancelButtonText: this.$t('form.cancel'),
                    buttonsStyling: false
                }).then((result)=> {
                    if (result.dismiss !== 'cancel') {
                        contractService.deleteContract(row.id).then(response => {
                            swal({
                                title: this.$t('form.deleted'),
                                text: this.$t('form.been_deleted'),
                                type: 'success',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                buttonsStyling: false
                            })
                            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
                            if (indexToDelete >= 0) {
                                this.tableData.splice(indexToDelete, 1)
                            }
                        });
                    }
                });



            },
            createItem(){

                this.$router.push({
                    name: 'createContract'
                })
            },
            trans_status(status){
                let result = 'Inactivo';
                if(status){
                    result = 'Activo';
                }
                return result
            },

            getName(arr, id) {
                //console.log(arr, id);
                let obj = arr.find(o => o._id === id);

                if(obj!==undefined){
                    return  obj.name;
                }
            },
            getVigency(rateplan){
                let valueReturn = 'Sin vigencia';
                if(rateplan.from && rateplan.from!==null){
                    valueReturn =  moment(rateplan.from, 'YYYY-MM-DD').locale('es').format('DD-MMM-YYYY');
                    valueReturn +=  ' <br> ';
                    valueReturn +=  moment(rateplan.to, 'YYYY-MM-DD').locale('es').format('DD-MMM-YYYY');
                }
                return valueReturn;
            },
            alertCapture(booking_url, codigo){
                let title_t = 'Contrato privado',
                    link = booking_url +'?rateplan[]='+ codigo,
                    text_succes = '',
                    text_error = '';

               //console.log('link', link)


                swal({
                    title: title_t,
                    html: '<div class="form-group">' +
                        '<input id="myTestField" type="text" class="form-control" value="'+link+'" readonly/>' +
                        '</div>',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    confirmButtonText: '<i class="fa fa-clone"></i>   Copiar al portapapeles',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    cancelButtonText: 'Cerrar',
                    buttonsStyling: false
                }).then( (result)=> {
                    if (result.dismiss !== 'cancel') {
                        let inputt = document.getElementById('myTestField');


                        inputt.select();

                        document.execCommand("copy");



                    }
                })
            },
            initLoading(text = 'Cargando...') {
                if(text==='Cargando...'){
                    this.$store.dispatch('CHANGES_ACTIVES', false);
                    this.records = [];
                }
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }

        },
        mounted() {
            this.fuseSearch = new Fuse(this.tableData, {keys: ['name']})
            this.changeProperty();
            this.checkedAction();
           
           
        },
    }
</script>
<style lang="scss">
    body{
        background: rgba(203, 203, 210, 0.15);
        .main-panel{
            background: none;
        }
    }
    .box-custom{
        position: relative;
        .bootstrap-switch{
            margin-left: 15px;
            margin-right: 15px;
        }

        .icon-left{
            line-height: 23px;
           /* left: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            line-height: 30px;*/

        }
    }
    .icon-right{
        line-height: 23px;
        margin-left: 15px;
        margin-right: 15px;
        width: 60px;
        display: inline-block;
        text-align: center;
    }
    .ul-rateplans{
        text-align: left;
        padding: 5px 0 5px 5px;
        span{
            width: 100%;
            display: inline-block;
        }
    }


</style>
